@media (width >= 980px) and (device-width >= 980px) {
  #logo {
    padding: 12px 0;
    display: block;
  }

  #logo img {
    max-width: 100%;
  }

  #region-content .region-inner, #region-sidebar-second .region-inner {
    padding: 35px;
  }

  #zone-postscript-wrapper {
    padding-top: 35px;
  }

  .context-services .content table {
    float: left;
    border: none;
    width: 45%;
    margin: 0 2.5%;
  }

  .product {
    float: left;
    width: 40%;
    min-height: 400px;
    margin-right: 10%;
  }

  #main-menu {
    text-align: right;
    visibility: visible;
    width: 100%;
    padding: 0;
    display: block;
    position: relative;
  }

  #main-menu li {
    margin: 0;
    padding: 8px;
    display: inline-block;
  }

  #main-menu a:link, #main-menu a:visited {
    padding: 5px;
    font-size: 15px;
    font-weight: bold;
  }

  .hamburger {
    display: none;
  }

  .grid-1, .grid-2, .grid-3, .grid-4, .grid-5, .grid-6, .grid-7, .grid-8, .grid-9, .grid-10, .grid-11, .grid-12, .grid-13, .grid-14, .grid-15, .grid-16, .grid-17, .grid-18, .grid-19, .grid-20, .grid-21, .grid-22, .grid-23, .grid-24 {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    display: inline;
    position: relative;
  }

  .push-1, .pull-1, .push-2, .pull-2, .push-3, .pull-3, .push-4, .pull-4, .push-5, .pull-5, .push-6, .pull-6, .push-7, .pull-7, .push-8, .pull-8, .push-9, .pull-9, .push-10, .pull-10, .push-11, .pull-11, .push-12, .pull-12, .push-13, .pull-13, .push-14, .pull-14, .push-15, .pull-15, .push-16, .pull-16, .push-17, .pull-17, .push-18, .pull-18, .push-19, .pull-19, .push-20, .pull-20, .push-21, .pull-21, .push-22, .pull-22, .push-23, .pull-23, .push-24, .pull-24 {
    position: relative;
  }

  .container-24 {
    width: 960px;
    margin-left: auto;
    margin-right: auto;
  }

  .container-24 .grid-1 {
    width: 20px;
  }

  .container-24 .grid-2 {
    width: 60px;
  }

  .container-24 .grid-3 {
    width: 100px;
  }

  .container-24 .grid-4 {
    width: 140px;
  }

  .container-24 .grid-5 {
    width: 180px;
  }

  .container-24 .grid-6 {
    width: 220px;
  }

  .container-24 .grid-7 {
    width: 260px;
  }

  .container-24 .grid-8 {
    width: 300px;
  }

  .container-24 .grid-9 {
    width: 340px;
  }

  .container-24 .grid-10 {
    width: 380px;
  }

  .container-24 .grid-11 {
    width: 420px;
  }

  .container-24 .grid-12 {
    width: 460px;
  }

  .container-24 .grid-13 {
    width: 500px;
  }

  .container-24 .grid-14 {
    width: 540px;
  }

  .container-24 .grid-15 {
    width: 580px;
  }

  .container-24 .grid-16 {
    width: 620px;
  }

  .container-24 .grid-17 {
    width: 660px;
  }

  .container-24 .grid-18 {
    width: 700px;
  }

  .container-24 .grid-19 {
    width: 740px;
  }

  .container-24 .grid-20 {
    width: 780px;
  }

  .container-24 .grid-21 {
    width: 820px;
  }

  .container-24 .grid-22 {
    width: 860px;
  }

  .container-24 .grid-23 {
    width: 900px;
  }

  .container-24 .grid-24 {
    width: 940px;
  }

  .container-24 .prefix-1 {
    padding-left: 40px;
  }

  .container-24 .prefix-2 {
    padding-left: 80px;
  }

  .container-24 .prefix-3 {
    padding-left: 120px;
  }

  .container-24 .prefix-4 {
    padding-left: 160px;
  }

  .container-24 .prefix-5 {
    padding-left: 200px;
  }

  .container-24 .prefix-6 {
    padding-left: 240px;
  }

  .container-24 .prefix-7 {
    padding-left: 280px;
  }

  .container-24 .prefix-8 {
    padding-left: 320px;
  }

  .container-24 .prefix-9 {
    padding-left: 360px;
  }

  .container-24 .prefix-10 {
    padding-left: 400px;
  }

  .container-24 .prefix-11 {
    padding-left: 440px;
  }

  .container-24 .prefix-12 {
    padding-left: 480px;
  }

  .container-24 .prefix-13 {
    padding-left: 520px;
  }

  .container-24 .prefix-14 {
    padding-left: 560px;
  }

  .container-24 .prefix-15 {
    padding-left: 600px;
  }

  .container-24 .prefix-16 {
    padding-left: 640px;
  }

  .container-24 .prefix-17 {
    padding-left: 680px;
  }

  .container-24 .prefix-18 {
    padding-left: 720px;
  }

  .container-24 .prefix-19 {
    padding-left: 760px;
  }

  .container-24 .prefix-20 {
    padding-left: 800px;
  }

  .container-24 .prefix-21 {
    padding-left: 840px;
  }

  .container-24 .prefix-22 {
    padding-left: 880px;
  }

  .container-24 .prefix-23 {
    padding-left: 920px;
  }

  .container-24 .suffix-1 {
    padding-right: 40px;
  }

  .container-24 .suffix-2 {
    padding-right: 80px;
  }

  .container-24 .suffix-3 {
    padding-right: 120px;
  }

  .container-24 .suffix-4 {
    padding-right: 160px;
  }

  .container-24 .suffix-5 {
    padding-right: 200px;
  }

  .container-24 .suffix-6 {
    padding-right: 240px;
  }

  .container-24 .suffix-7 {
    padding-right: 280px;
  }

  .container-24 .suffix-8 {
    padding-right: 320px;
  }

  .container-24 .suffix-9 {
    padding-right: 360px;
  }

  .container-24 .suffix-10 {
    padding-right: 400px;
  }

  .container-24 .suffix-11 {
    padding-right: 440px;
  }

  .container-24 .suffix-12 {
    padding-right: 480px;
  }

  .container-24 .suffix-13 {
    padding-right: 520px;
  }

  .container-24 .suffix-14 {
    padding-right: 560px;
  }

  .container-24 .suffix-15 {
    padding-right: 600px;
  }

  .container-24 .suffix-16 {
    padding-right: 640px;
  }

  .container-24 .suffix-17 {
    padding-right: 680px;
  }

  .container-24 .suffix-18 {
    padding-right: 720px;
  }

  .container-24 .suffix-19 {
    padding-right: 760px;
  }

  .container-24 .suffix-20 {
    padding-right: 800px;
  }

  .container-24 .suffix-21 {
    padding-right: 840px;
  }

  .container-24 .suffix-22 {
    padding-right: 880px;
  }

  .container-24 .suffix-23 {
    padding-right: 920px;
  }

  .container-24 .push-1 {
    left: 40px;
  }

  .container-24 .push-2 {
    left: 80px;
  }

  .container-24 .push-3 {
    left: 120px;
  }

  .container-24 .push-4 {
    left: 160px;
  }

  .container-24 .push-5 {
    left: 200px;
  }

  .container-24 .push-6 {
    left: 240px;
  }

  .container-24 .push-7 {
    left: 280px;
  }

  .container-24 .push-8 {
    left: 320px;
  }

  .container-24 .push-9 {
    left: 360px;
  }

  .container-24 .push-10 {
    left: 400px;
  }

  .container-24 .push-11 {
    left: 440px;
  }

  .container-24 .push-12 {
    left: 480px;
  }

  .container-24 .push-13 {
    left: 520px;
  }

  .container-24 .push-14 {
    left: 560px;
  }

  .container-24 .push-15 {
    left: 600px;
  }

  .container-24 .push-16 {
    left: 640px;
  }

  .container-24 .push-17 {
    left: 680px;
  }

  .container-24 .push-18 {
    left: 720px;
  }

  .container-24 .push-19 {
    left: 760px;
  }

  .container-24 .push-20 {
    left: 800px;
  }

  .container-24 .push-21 {
    left: 840px;
  }

  .container-24 .push-22 {
    left: 880px;
  }

  .container-24 .push-23 {
    left: 920px;
  }

  .container-24 .pull-1 {
    left: -40px;
  }

  .container-24 .pull-2 {
    left: -80px;
  }

  .container-24 .pull-3 {
    left: -120px;
  }

  .container-24 .pull-4 {
    left: -160px;
  }

  .container-24 .pull-5 {
    left: -200px;
  }

  .container-24 .pull-6 {
    left: -240px;
  }

  .container-24 .pull-7 {
    left: -280px;
  }

  .container-24 .pull-8 {
    left: -320px;
  }

  .container-24 .pull-9 {
    left: -360px;
  }

  .container-24 .pull-10 {
    left: -400px;
  }

  .container-24 .pull-11 {
    left: -440px;
  }

  .container-24 .pull-12 {
    left: -480px;
  }

  .container-24 .pull-13 {
    left: -520px;
  }

  .container-24 .pull-14 {
    left: -560px;
  }

  .container-24 .pull-15 {
    left: -600px;
  }

  .container-24 .pull-16 {
    left: -640px;
  }

  .container-24 .pull-17 {
    left: -680px;
  }

  .container-24 .pull-18 {
    left: -720px;
  }

  .container-24 .pull-19 {
    left: -760px;
  }

  .container-24 .pull-20 {
    left: -800px;
  }

  .container-24 .pull-21 {
    left: -840px;
  }

  .container-24 .pull-22 {
    left: -880px;
  }

  .container-24 .pull-23 {
    left: -920px;
  }

  .grid-1, .grid-2, .grid-3, .grid-4, .grid-5, .grid-6, .grid-7, .grid-8, .grid-9, .grid-10, .grid-11, .grid-12 {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    display: inline;
    position: relative;
  }

  .push-1, .pull-1, .push-2, .pull-2, .push-3, .pull-3, .push-4, .pull-4, .push-5, .pull-5, .push-6, .pull-6, .push-7, .pull-7, .push-8, .pull-8, .push-9, .pull-9, .push-10, .pull-10, .push-11, .pull-11, .push-12, .pull-12 {
    position: relative;
  }

  .alpha {
    margin-left: 0;
  }

  .omega {
    margin-right: 0;
  }

  body {
    min-width: 960px;
  }

  .container-12 {
    width: 960px;
    margin-left: auto;
    margin-right: auto;
  }

  .container-12 .grid-1 {
    width: 60px;
  }

  .container-12 .grid-2 {
    width: 140px;
  }

  .container-12 .grid-3 {
    width: 220px;
  }

  .container-12 .grid-4 {
    width: 300px;
  }

  .container-12 .grid-5 {
    width: 380px;
  }

  .container-12 .grid-6 {
    width: 460px;
  }

  .container-12 .grid-7 {
    width: 540px;
  }

  .container-12 .grid-8 {
    width: 620px;
  }

  .container-12 .grid-9 {
    width: 700px;
  }

  .container-12 .grid-10 {
    width: 780px;
  }

  .container-12 .grid-11 {
    width: 860px;
  }

  .container-12 .grid-12 {
    width: 940px;
  }

  .container-12 .prefix-1 {
    padding-left: 80px;
  }

  .container-12 .prefix-2 {
    padding-left: 160px;
  }

  .container-12 .prefix-3 {
    padding-left: 240px;
  }

  .container-12 .prefix-4 {
    padding-left: 320px;
  }

  .container-12 .prefix-5 {
    padding-left: 400px;
  }

  .container-12 .prefix-6 {
    padding-left: 480px;
  }

  .container-12 .prefix-7 {
    padding-left: 560px;
  }

  .container-12 .prefix-8 {
    padding-left: 640px;
  }

  .container-12 .prefix-9 {
    padding-left: 720px;
  }

  .container-12 .prefix-10 {
    padding-left: 800px;
  }

  .container-12 .prefix-11 {
    padding-left: 880px;
  }

  .container-12 .suffix-1 {
    padding-right: 80px;
  }

  .container-12 .suffix-2 {
    padding-right: 160px;
  }

  .container-12 .suffix-3 {
    padding-right: 240px;
  }

  .container-12 .suffix-4 {
    padding-right: 320px;
  }

  .container-12 .suffix-5 {
    padding-right: 400px;
  }

  .container-12 .suffix-6 {
    padding-right: 480px;
  }

  .container-12 .suffix-7 {
    padding-right: 560px;
  }

  .container-12 .suffix-8 {
    padding-right: 640px;
  }

  .container-12 .suffix-9 {
    padding-right: 720px;
  }

  .container-12 .suffix-10 {
    padding-right: 800px;
  }

  .container-12 .suffix-11 {
    padding-right: 880px;
  }

  .container-12 .push-1 {
    left: 80px;
  }

  .container-12 .push-2 {
    left: 160px;
  }

  .container-12 .push-3 {
    left: 240px;
  }

  .container-12 .push-4 {
    left: 320px;
  }

  .container-12 .push-5 {
    left: 400px;
  }

  .container-12 .push-6 {
    left: 480px;
  }

  .container-12 .push-7 {
    left: 560px;
  }

  .container-12 .push-8 {
    left: 640px;
  }

  .container-12 .push-9 {
    left: 720px;
  }

  .container-12 .push-10 {
    left: 800px;
  }

  .container-12 .push-11 {
    left: 880px;
  }

  .container-12 .pull-1 {
    left: -80px;
  }

  .container-12 .pull-2 {
    left: -160px;
  }

  .container-12 .pull-3 {
    left: -240px;
  }

  .container-12 .pull-4 {
    left: -320px;
  }

  .container-12 .pull-5 {
    left: -400px;
  }

  .container-12 .pull-6 {
    left: -480px;
  }

  .container-12 .pull-7 {
    left: -560px;
  }

  .container-12 .pull-8 {
    left: -640px;
  }

  .container-12 .pull-9 {
    left: -720px;
  }

  .container-12 .pull-10 {
    left: -800px;
  }

  .container-12 .pull-11 {
    left: -880px;
  }
}

@media (width <= 980px) and (device-width <= 980px) {
  #logo {
    padding: 12px 0;
    display: block;
  }

  #logo img {
    max-width: 100%;
  }

  #region-content .region-inner, #region-sidebar-second .region-inner {
    padding: 35px;
  }

  #zone-postscript-wrapper {
    padding-top: 35px;
  }

  .context-services .content table {
    float: left;
    border: none;
    width: 45%;
    margin: 0 2.5%;
  }

  .product {
    float: left;
    width: 40%;
    min-height: 400px;
    margin-right: 10%;
  }

  .grid-1, .grid-2, .grid-3, .grid-4, .grid-5, .grid-6, .grid-7, .grid-8, .grid-9, .grid-10, .grid-11, .grid-12, .grid-13, .grid-14, .grid-15, .grid-16, .grid-17, .grid-18, .grid-19, .grid-20, .grid-21, .grid-22, .grid-23, .grid-24 {
    float: left;
    margin-left: 1%;
    margin-right: 1%;
    display: inline;
    position: relative;
  }

  .push-1, .pull-1, .push-2, .pull-2, .push-3, .pull-3, .push-4, .pull-4, .push-5, .pull-5, .push-6, .pull-6, .push-7, .pull-7, .push-8, .pull-8, .push-9, .pull-9, .push-10, .pull-10, .push-11, .pull-11, .push-12, .pull-12, .push-13, .pull-13, .push-14, .pull-14, .push-15, .pull-15, .push-16, .pull-16, .push-17, .pull-17, .push-18, .pull-18, .push-19, .pull-19, .push-20, .pull-20, .push-21, .pull-21, .push-22, .pull-22, .push-23, .pull-23, .push-24, .pull-24 {
    position: relative;
  }

  .container-24 .grid-1 {
    width: 2.167%;
  }

  .container-24 .grid-2 {
    width: 6.333%;
  }

  .container-24 .grid-3 {
    width: 10.5%;
  }

  .container-24 .grid-4 {
    width: 14.667%;
  }

  .container-24 .grid-5 {
    width: 18.833%;
  }

  .container-24 .grid-6 {
    width: 23%;
  }

  .container-24 .grid-7 {
    width: 27.167%;
  }

  .container-24 .grid-8 {
    width: 31.333%;
  }

  .container-24 .grid-9 {
    width: 35.5%;
  }

  .container-24 .grid-10 {
    width: 39.667%;
  }

  .container-24 .grid-11 {
    width: 43.833%;
  }

  .container-24 .grid-12 {
    width: 48%;
  }

  .container-24 .grid-13 {
    width: 52.167%;
  }

  .container-24 .grid-14 {
    width: 56.333%;
  }

  .container-24 .grid-15 {
    width: 60.5%;
  }

  .container-24 .grid-16 {
    width: 64.667%;
  }

  .container-24 .grid-17 {
    width: 68.833%;
  }

  .container-24 .grid-18 {
    width: 73%;
  }

  .container-24 .grid-19 {
    width: 77.167%;
  }

  .container-24 .grid-20 {
    width: 81.333%;
  }

  .container-24 .grid-21 {
    width: 85.5%;
  }

  .container-24 .grid-22 {
    width: 89.667%;
  }

  .container-24 .grid-23 {
    width: 93.833%;
  }

  .container-24 .grid-24 {
    width: 98%;
  }

  .container-24 .prefix-1 {
    padding-left: 4.167%;
  }

  .container-24 .prefix-2 {
    padding-left: 8.333%;
  }

  .container-24 .prefix-3 {
    padding-left: 12.5%;
  }

  .container-24 .prefix-4 {
    padding-left: 16.667%;
  }

  .container-24 .prefix-5 {
    padding-left: 20.833%;
  }

  .container-24 .prefix-6 {
    padding-left: 25%;
  }

  .container-24 .prefix-7 {
    padding-left: 29.167%;
  }

  .container-24 .prefix-8 {
    padding-left: 33.333%;
  }

  .container-24 .prefix-9 {
    padding-left: 37.5%;
  }

  .container-24 .prefix-10 {
    padding-left: 41.667%;
  }

  .container-24 .prefix-11 {
    padding-left: 45.833%;
  }

  .container-24 .prefix-12 {
    padding-left: 50%;
  }

  .container-24 .prefix-13 {
    padding-left: 54.167%;
  }

  .container-24 .prefix-14 {
    padding-left: 58.333%;
  }

  .container-24 .prefix-15 {
    padding-left: 62.5%;
  }

  .container-24 .prefix-16 {
    padding-left: 66.667%;
  }

  .container-24 .prefix-17 {
    padding-left: 70.833%;
  }

  .container-24 .prefix-18 {
    padding-left: 75%;
  }

  .container-24 .prefix-19 {
    padding-left: 79.167%;
  }

  .container-24 .prefix-20 {
    padding-left: 83.333%;
  }

  .container-24 .prefix-21 {
    padding-left: 87.5%;
  }

  .container-24 .prefix-22 {
    padding-left: 91.667%;
  }

  .container-24 .prefix-23 {
    padding-left: 95.833%;
  }

  .container-24 .suffix-1 {
    padding-right: 4.167%;
  }

  .container-24 .suffix-2 {
    padding-right: 8.333%;
  }

  .container-24 .suffix-3 {
    padding-right: 12.5%;
  }

  .container-24 .suffix-4 {
    padding-right: 16.667%;
  }

  .container-24 .suffix-5 {
    padding-right: 20.833%;
  }

  .container-24 .suffix-6 {
    padding-right: 25%;
  }

  .container-24 .suffix-7 {
    padding-right: 29.167%;
  }

  .container-24 .suffix-8 {
    padding-right: 33.333%;
  }

  .container-24 .suffix-9 {
    padding-right: 37.5%;
  }

  .container-24 .suffix-10 {
    padding-right: 41.667%;
  }

  .container-24 .suffix-11 {
    padding-right: 45.833%;
  }

  .container-24 .suffix-12 {
    padding-right: 50%;
  }

  .container-24 .suffix-13 {
    padding-right: 54.167%;
  }

  .container-24 .suffix-14 {
    padding-right: 58.333%;
  }

  .container-24 .suffix-15 {
    padding-right: 62.5%;
  }

  .container-24 .suffix-16 {
    padding-right: 66.667%;
  }

  .container-24 .suffix-17 {
    padding-right: 70.833%;
  }

  .container-24 .suffix-18 {
    padding-right: 75%;
  }

  .container-24 .suffix-19 {
    padding-right: 79.167%;
  }

  .container-24 .suffix-20 {
    padding-right: 83.333%;
  }

  .container-24 .suffix-21 {
    padding-right: 87.5%;
  }

  .container-24 .suffix-22 {
    padding-right: 91.667%;
  }

  .container-24 .suffix-23 {
    padding-right: 95.833%;
  }

  .container-24 .push-1 {
    left: 4.167%;
  }

  .container-24 .push-2 {
    left: 8.333%;
  }

  .container-24 .push-3 {
    left: 12.5%;
  }

  .container-24 .push-4 {
    left: 16.667%;
  }

  .container-24 .push-5 {
    left: 20.833%;
  }

  .container-24 .push-6 {
    left: 25%;
  }

  .container-24 .push-7 {
    left: 29.167%;
  }

  .container-24 .push-8 {
    left: 33.333%;
  }

  .container-24 .push-9 {
    left: 37.5%;
  }

  .container-24 .push-10 {
    left: 41.667%;
  }

  .container-24 .push-11 {
    left: 45.833%;
  }

  .container-24 .push-12 {
    left: 50%;
  }

  .container-24 .push-13 {
    left: 54.167%;
  }

  .container-24 .push-14 {
    left: 58.333%;
  }

  .container-24 .push-15 {
    left: 62.5%;
  }

  .container-24 .push-16 {
    left: 66.667%;
  }

  .container-24 .push-17 {
    left: 70.833%;
  }

  .container-24 .push-18 {
    left: 75%;
  }

  .container-24 .push-19 {
    left: 79.167%;
  }

  .container-24 .push-20 {
    left: 83.333%;
  }

  .container-24 .push-21 {
    left: 87.5%;
  }

  .container-24 .push-22 {
    left: 91.667%;
  }

  .container-24 .push-23 {
    left: 95.833%;
  }

  .container-24 .pull-1 {
    left: -4.167%;
  }

  .container-24 .pull-2 {
    left: -8.333%;
  }

  .container-24 .pull-3 {
    left: -12.5%;
  }

  .container-24 .pull-4 {
    left: -16.667%;
  }

  .container-24 .pull-5 {
    left: -20.833%;
  }

  .container-24 .pull-6 {
    left: -25%;
  }

  .container-24 .pull-7 {
    left: -29.167%;
  }

  .container-24 .pull-8 {
    left: -33.333%;
  }

  .container-24 .pull-9 {
    left: -37.5%;
  }

  .container-24 .pull-10 {
    left: -41.667%;
  }

  .container-24 .pull-11 {
    left: -45.833%;
  }

  .container-24 .pull-12 {
    left: -50%;
  }

  .container-24 .pull-13 {
    left: -54.167%;
  }

  .container-24 .pull-14 {
    left: -58.333%;
  }

  .container-24 .pull-15 {
    left: -62.5%;
  }

  .container-24 .pull-16 {
    left: -66.667%;
  }

  .container-24 .pull-17 {
    left: -70.833%;
  }

  .container-24 .pull-18 {
    left: -75%;
  }

  .container-24 .pull-19 {
    left: -79.167%;
  }

  .container-24 .pull-20 {
    left: -83.333%;
  }

  .container-24 .pull-21 {
    left: -87.5%;
  }

  .container-24 .pull-22 {
    left: -91.667%;
  }

  .container-24 .pull-23 {
    left: -95.833%;
  }

  .grid-1, .grid-2, .grid-3, .grid-4, .grid-5, .grid-6, .grid-7, .grid-8, .grid-9, .grid-10, .grid-11, .grid-12 {
    float: left;
    margin-left: 1%;
    margin-right: 1%;
    display: inline;
    position: relative;
  }

  .push-1, .pull-1, .push-2, .pull-2, .push-3, .pull-3, .push-4, .pull-4, .push-5, .pull-5, .push-6, .pull-6, .push-7, .pull-7, .push-8, .pull-8, .push-9, .pull-9, .push-10, .pull-10, .push-11, .pull-11, .push-12, .pull-12 {
    position: relative;
  }

  .alpha {
    margin-left: 0;
  }

  .omega {
    margin-right: 0;
  }

  .container-12 .grid-1 {
    width: 6.333%;
  }

  .container-12 .grid-2 {
    width: 14.667%;
  }

  .container-12 .grid-3 {
    width: 23%;
  }

  .container-12 .grid-4 {
    width: 31.333%;
  }

  .container-12 .grid-5 {
    width: 39.667%;
  }

  .container-12 .grid-6 {
    width: 48%;
  }

  .container-12 .grid-7 {
    width: 56.333%;
  }

  .container-12 .grid-8 {
    width: 64.667%;
  }

  .container-12 .grid-9 {
    width: 73%;
  }

  .container-12 .grid-10 {
    width: 81.333%;
  }

  .container-12 .grid-11 {
    width: 89.667%;
  }

  .container-12 .grid-12 {
    width: 98%;
  }

  .container-12 .prefix-1 {
    padding-left: 8.333%;
  }

  .container-12 .prefix-2 {
    padding-left: 16.667%;
  }

  .container-12 .prefix-3 {
    padding-left: 25%;
  }

  .container-12 .prefix-4 {
    padding-left: 33.333%;
  }

  .container-12 .prefix-5 {
    padding-left: 41.667%;
  }

  .container-12 .prefix-6 {
    padding-left: 50%;
  }

  .container-12 .prefix-7 {
    padding-left: 58.333%;
  }

  .container-12 .prefix-8 {
    padding-left: 66.667%;
  }

  .container-12 .prefix-9 {
    padding-left: 75%;
  }

  .container-12 .prefix-10 {
    padding-left: 83.333%;
  }

  .container-12 .prefix-11 {
    padding-left: 91.667%;
  }

  .container-12 .suffix-1 {
    padding-right: 8.333%;
  }

  .container-12 .suffix-2 {
    padding-right: 16.667%;
  }

  .container-12 .suffix-3 {
    padding-right: 25%;
  }

  .container-12 .suffix-4 {
    padding-right: 33.333%;
  }

  .container-12 .suffix-5 {
    padding-right: 41.667%;
  }

  .container-12 .suffix-6 {
    padding-right: 50%;
  }

  .container-12 .suffix-7 {
    padding-right: 58.333%;
  }

  .container-12 .suffix-8 {
    padding-right: 66.667%;
  }

  .container-12 .suffix-9 {
    padding-right: 75%;
  }

  .container-12 .suffix-10 {
    padding-right: 83.333%;
  }

  .container-12 .suffix-11 {
    padding-right: 91.667%;
  }

  .container-12 .push-1 {
    left: 8.333%;
  }

  .container-12 .push-2 {
    left: 16.667%;
  }

  .container-12 .push-3 {
    left: 25%;
  }

  .container-12 .push-4 {
    left: 33.333%;
  }

  .container-12 .push-5 {
    left: 41.667%;
  }

  .container-12 .push-6 {
    left: 50%;
  }

  .container-12 .push-7 {
    left: 58.333%;
  }

  .container-12 .push-8 {
    left: 66.667%;
  }

  .container-12 .push-9 {
    left: 75%;
  }

  .container-12 .push-10 {
    left: 83.333%;
  }

  .container-12 .push-11 {
    left: 91.667%;
  }

  .container-12 .pull-1 {
    left: -8.333%;
  }

  .container-12 .pull-2 {
    left: -16.667%;
  }

  .container-12 .pull-3 {
    left: -25%;
  }

  .container-12 .pull-4 {
    left: -33.333%;
  }

  .container-12 .pull-5 {
    left: -41.667%;
  }

  .container-12 .pull-6 {
    left: -50%;
  }

  .container-12 .pull-7 {
    left: -58.333%;
  }

  .container-12 .pull-8 {
    left: -66.667%;
  }

  .container-12 .pull-9 {
    left: -75%;
  }

  .container-12 .pull-10 {
    left: -83.333%;
  }

  .container-12 .pull-11 {
    left: -91.667%;
  }
}
/*# sourceMappingURL=about.f2e4fe4c.css.map */
